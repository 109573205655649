@charset "UTF-8";
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.l-lowerCaption__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/*
ユーティリティ系おまとめファイル
*/
.c-topicspath {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 5px;
  text-align: left;
}
.c-topicspath li {
  display: inline-block;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .c-topicspath li {
    font-size: 0.7em;
  }
}
.c-topicspath li::after {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  display: inline-block;
  margin-left: 8px;
}
.c-topicspath li:last-child::after {
  content: none;
}

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 200px;
  background-image: url(http://placeholder.pics/svg/1200x300/FF6B6B);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
.l-lowerCaption__title {
  color: #fff;
  max-width: 1170px;
  text-align: center;
  padding: 0 10px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .l-lowerCaption__title {
    width: 100%;
    padding: 0 20px;
  }
}